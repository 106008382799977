.walletModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    overflow: auto;
    border-radius: 12.5px;
    outline: none;

    width: 50%;
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

@media only screen and (max-width: 767px) {
    .walletModal {
        width: 90%;
        font-size: 120%;
        padding-bottom: 15%;
    }
}

@media only screen and (max-width: 1260px) {
    .walletModal {
        width: 90%;
        font-size: 120%;
    }
}