.dropdown {
    display: inline-block;
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    font-size: 90%;
    text-align: left;
    font-weight: bold;
    top: 100%;
    z-index: 10000000000000000000000000;
}


.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a {}

.dropdown-content a:hover {}



.dropdownbtn {
    border-radius: 12.5px;
    color: red !important;
    background: #fff !important;
    font-size: 80%;
    font-weight: bold;
    padding-right: 7.5%;
    padding-left: 7.5%;
}